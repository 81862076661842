export const COMMUNITY_USER_ACTION_BUTTON_MESSAGE_FRAGMENT = `
  fragment communityUserActionButtonMessageFragment on CommunityUser {
    uid
    firstName
    lastName
    jobTitle
    pictureFileResource {
        uid
        schemaCode
        path
    }
    _ourConnection(myUid: "%authUser%") {
        uid
        linkState
        connectedUser {
            uid
            name
        }
    }
    _ourDirectMessageGroup(myUid: "%authUser%", filter: {type: "DIRECT"}) {
      uid
      schemaCode
      groupType: type
      target {
          uid
          __typename
          ... on Session {
              name
              imageFileResource {
                  path
                  schemaCode
              }
              startTime
          }
          ... on Exhibitor {
              name
              logoFileResource {
                  path
                  schemaCode
              }
          }
          ... on Meeting {
              subject
              endTimestamp
              startTimestamp
              createdTime
              creatorUser {
                  uid
                  firstName
                  lastName
                  name
                  pictureFileResource {
                      schemaCode
                      path
                  }
              }
              participants {
                  uid
                  state
                  user {
                      uid
                      firstName
                      lastName
                      name
                      pictureFileResource {
                          schemaCode
                          path
                      }
                  }
              }
          }
      }
      guests {
          uid
          schemaCode
          token
          group {
              uid
          }
      }
      users {
          uid
          firstName
          lastName
          jobTitle
          pictureFileResource {
              uid
              schemaCode
              path
          }
          _ourConnection(myUid: "%authUser%") {
              uid
              linkState
              connectedUser {
                  uid
                  name
              }
          }
      }
      inactiveUsers {
          uid
          firstName
          lastName
          jobTitle
          pictureFileResource {
              uid
              schemaCode
              path
          }
          _ourConnection(myUid: "%authUser%") {
              uid
              linkState
              connectedUser {
                  uid
                  name
              }
          }
      }
      messages(orderBy: senttime_desc) {
          uid
          type
          content
          senttime
          senttimestamp
          user{
              uid
              firstName
              lastName
              jobTitle
              pictureFileResource {
                  uid
                  schemaCode
                  path
              }
          }
      }
      myState: _myState(myUid: "%authUser%") {
          uid
          lastReadTimestamp
          hideMessageBeforeTimestamp
          user {
              uid
              firstName
              lastName
              jobTitle
              pictureFileResource {
                  uid
                  schemaCode
                  path
              }
          }
      }
    }
  }
`;
